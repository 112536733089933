<div class="container-fluid mt-1 mb-1 logo">
    <img id="logo"  src="assets/images/logo_definitiu.png" >
</div>

<nav class="navbar navbar-expand-lg">
    <div class="container-fluid">
        <div class="d-flex justify-content-between align-items-center w-100">
            <button (click)="goBack()" *ngIf="!shouldHideButton()" class="form-control button w-120">
                <i class="fa fa-arrow-left"></i>
                Volver
            </button>
            <button (click)="refreshNav()" *ngIf="shouldHideButtonRefresh()" class="form-control button w-160">
                Limpiar filtro QR
            </button>
            <span class="navbar-text section-name">{{ nameSection }}</span>
            <div *ngIf="shouldHideButton()" class="text-center">
                <span class="navbar-text user-name">Bienvenido, {{ nameXofer }}</span>
                <i (click)="signOut()" class="fa fa-sign-out"></i>
            </div>
        </div>
    </div>
</nav>
