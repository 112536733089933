import { Component, OnInit, ViewChild } from '@angular/core';
import { Albaran } from '../../../models/albaran';
import { AlbaranService } from "../../../services/albaran.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Matricula } from "../../../models/matricula";
import { AuthService } from "../../../services/auth.service";
import { Lloc } from "../../../models/lloc";
import { lastValueFrom } from 'rxjs';
import { ZXingScannerComponent } from "@zxing/ngx-scanner";
import { BarcodeFormat } from "@zxing/library";
import { ToastController } from "@ionic/angular";
import { QrDriver } from "../../../drivers/qr.driver";
import { TransportStatus } from "../../../data/TransportStatus";

@Component({
    selector: 'app-albaran-list',
    templateUrl: './albaran-list.component.html',
    styleUrls: ['./albaran-list.component.scss'],
    standalone: false
})
export class AlbaranListComponent implements OnInit {

    nameSection = "Lista albaranes";

    @ViewChild('selectElement') selectElement!: any;

    inputFiltroAlbaran: string = "";
    inputFiltroAlbaranDate: string = "";

    nomXofer: any;
    matriculaXofer: any;
    selectedTuition: any;

    matriculas: Matricula[] = [];
    albaranes: Albaran[] = [];

    valorRecibido: number = -1;
    tareasHechasTotales: number = 0;
    tareasPendientesTotales: number = 0;

    loaderShow: boolean = false;
    boolHiddeBtnClearFilters: boolean = true;

    showAlertErrorGetAlbaranes: boolean = false;
    messageErrorGetAlbaranes: string = "";

    showQuestionDestiny: boolean = false;
    showAlertSaveTracking: boolean = false;
    messageAlertSaveTracking: string = "";
    listFirstDestinyXofer: Lloc[] = [];
    selectedCheckboxId: string | null = null;
    firstDestiny: any;

    showAdvanceAlbaran: boolean = false;
    showCardAdvanceAlbaran: boolean = false;
    showCameraAdvanceAlbarans: boolean = false;
    showMessageAdvancedAlbaranNotFound: boolean = false;
    albaranId: string = "";
    advancedAlbaran: Albaran = new Albaran();

    @ViewChild('scanner', {static: true}) scanner: ZXingScannerComponent | undefined;

    public procesing = false;
    public enabled = true;

    name = "Lector QR";

    formatsEnabled: BarcodeFormat[] = [
        BarcodeFormat.CODE_128,
        BarcodeFormat.DATA_MATRIX,
        BarcodeFormat.EAN_13,
        BarcodeFormat.QR_CODE
    ];

    availableDevices?: MediaDeviceInfo[];
    deviceCurrent?: MediaDeviceInfo;
    deviceSelected?: MediaDeviceInfo;

    showCheck = false;
    showFail = false;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private albaranService: AlbaranService,
        private auth: AuthService,
        private qrDriver: QrDriver,
        private toast: ToastController,
    ) {
    }

    async ngOnInit() {
        this.route.params.subscribe(params => {
            this.valorRecibido = params['idAlbara'];
        });

        this.loaderShow = true;

        if(this.valorRecibido){
            let lasTransportDate: any = await lastValueFrom(this.albaranService.getLastTransportDate(this.valorRecibido));
            if (lasTransportDate != 0 || lasTransportDate != '0'){
                await this.router.navigate(['/albaran', this.valorRecibido, lasTransportDate]);
            }
        }

        try {
            const xofers: any = await lastValueFrom(this.albaranService.getNameXofer());
            this.nomXofer = xofers.nomXofer;
            this.matriculaXofer = xofers.matriculaXofer;
            this.matriculas = xofers.matriculas;

            if (localStorage.getItem('matricula') === null) {
                localStorage.setItem('matricula', this.matriculaXofer);
            }

            this.selectedTuition = localStorage.getItem('matricula');

            const actualDate = new Date();
            const dd = String(actualDate.getDate()).padStart(2, '0');
            const mm = String(actualDate.getMonth() + 1).padStart(2, '0');
            const yyyy = actualDate.getFullYear();

            this.inputFiltroAlbaranDate = yyyy + '-' + mm + '-' + dd;
            //this.inputFiltroAlbaranDate = "2015-08-26";

            let dateAdvancedAlbarans: any = localStorage.getItem("dateAdvancedAlbarans");

            if (dateAdvancedAlbarans !== this.inputFiltroAlbaranDate) {
                localStorage.removeItem("advancedAlbarans");
                localStorage.removeItem("dateAdvancedAlbarans");
            }

            let advancedAlbarans = localStorage.getItem("advancedAlbarans");

            let albaranes: any;

            if (advancedAlbarans)
                albaranes = await lastValueFrom(this.albaranService.getAlbaranes(advancedAlbarans));
            else
                albaranes = await lastValueFrom(this.albaranService.getAlbaranes(null));

            this.tareasHechasTotales = 0;
            this.tareasPendientesTotales = 0;
            this.albaranes = [];

            if (albaranes.length > 0) {
                for(let albaran of albaranes){
                    if (this.valorRecibido === undefined || this.valorRecibido.toString() === albaran.id.toString()) {
                        this.albaranes.push(albaran);
                        this.tareasHechasTotales += parseInt(albaran.numeroTareasHechas, 10);
                        this.tareasPendientesTotales += parseInt(albaran.numeroTareas, 10);
                    }
                }
            }

            const registros = Object.values(this.albaranes);

            registros.sort((a, b) => {
                if (a.estat === "Pre assignat" && b.estat !== "Pre assignat") {
                    return -1;
                } else if (a.estat !== "Pre assignat" && b.estat === "Pre assignat") {
                    return 1;
                } else {
                    return 0;
                }
            });

            this.albaranes = registros;

            this.showQuestionDestiny = this.auth.showQuestionDestiny;

            if (this.showQuestionDestiny) {
                const destiny: any = await lastValueFrom(this.albaranService.getListFirstDestinyXofer(this.inputFiltroAlbaranDate));
                this.listFirstDestinyXofer = destiny;
                this.auth.showQuestionDestiny = false;
            }

            this.loaderShow = false;
        } catch (error) {
            await this.router.navigate(['/login']);
            this.loaderShow = false;
            console.log(error);
        }
    }

    saveDestinationPlaceId(idLloc: any) {
        if (this.selectedCheckboxId === idLloc) {
            this.selectedCheckboxId = null;
            this.firstDestiny = null;
        } else {
            this.selectedCheckboxId = idLloc;
            this.firstDestiny = idLloc;
        }
    }

    saveFirstDestiny() {
        this.loaderShow = true;
        this.showQuestionDestiny = false;

        this.albaranService.saveFirstDestinyXofer(this.firstDestiny, this.selectedTuition, this.inputFiltroAlbaranDate).subscribe(
            {
                next: next => {
                    this.showAlertSaveTracking = true;
                    this.messageAlertSaveTracking = "Guardado correctamente.";

                    this.loaderShow = false;
                }, error: error => {
                    this.showAlertSaveTracking = true;
                    this.messageAlertSaveTracking = "Error al guardar el sitio al que te diriges.";

                    this.loaderShow = false;
                }
            }
        );
    }

    closeSaveFirstDestiny() {
        this.showQuestionDestiny = false;
    }

    closeAlertSaveFirstDestiny() {
        this.showAlertSaveTracking = false;
    }

    getActiveSpans(deliveryNote: Albaran): string[] {
        const spans = [];
        if (deliveryNote.esAdr === 1) spans.push('ADR');
        if (deliveryNote.esPortaElevadora === 1) spans.push('PE');
        if (deliveryNote.esExpress === 1) spans.push('EX');
        if (deliveryNote.esRemuntable === 1) spans.push('R');
        if (deliveryNote.pnstrm === 1) spans.push('PNS');
        return spans;
    }

    getClassForStatus(status: string): string {
        switch (status) {
            case TransportStatus.preAssigned:
                return 'pre-assigned-delivery-note';
            case TransportStatus.assigned:
                return 'assigned-delivery-note';
            case TransportStatus.partiallyAssigned:
                return 'partially-assigned-delivery-note';
            case TransportStatus.initiated:
                return 'initiated-delivery-note';
            case TransportStatus.completed:
                return 'completed-delivery-note';
            case TransportStatus.postponed:
                return 'postponed-delivery-note';
            case TransportStatus.cancelled:
                return 'cancelled-delivery-note';
            case TransportStatus.pending:
                return 'pending-delivery-note';
            case TransportStatus.preCompleted:
                return 'pre-completed-delivery-note';
            default:
                return '';
        }
    }

    getAlbaranesFiltrados() {
        let fecha = new Date('2000-01-01')
        let fechafiltro = new Date(this.inputFiltroAlbaranDate)
        let currentDate = new Date();

        if (currentDate < fechafiltro) {
            this.messageErrorGetAlbaranes = "No se pueden mostrar albaranes con fechas posteriores a la actual.";
            this.showAlertErrorGetAlbaranes = true;
            this.albaranes = [];

            return;
        }

        if (fechafiltro > fecha) {
            this.boolHiddeBtnClearFilters = !this.boolHiddeBtnClearFilters;
            if (this.boolHiddeBtnClearFilters)
                this.boolHiddeBtnClearFilters = !this.boolHiddeBtnClearFilters;

            this.tareasHechasTotales = 0;
            this.tareasPendientesTotales = 0;

            this.albaranService.getAlbaranesFiltrados(this.inputFiltroAlbaranDate, this.inputFiltroAlbaran).subscribe(
                {
                    next: albaranes => {
                        this.showAlertErrorGetAlbaranes = false;

                        this.albaranes = albaranes;

                        albaranes.forEach((albaran: any) => {
                            this.tareasHechasTotales += parseInt(albaran.numeroTareasHechas, 10);
                            this.tareasPendientesTotales += parseInt(albaran.numeroTareas, 10);
                        });

                        //this.tareasPendientesTotales = albaranes.length * 6;

                        const registros = Object.values(this.albaranes);

                        registros.sort((a, b) => {
                            if (a.statusCode == TransportStatus.preAssigned && b.statusCode != TransportStatus.preAssigned) {
                                return -1;
                            } else if (a.statusCode != TransportStatus.preAssigned && b.statusCode == TransportStatus.preAssigned) {
                                return 1;
                            }

                            if (a.statusCode == TransportStatus.partiallyAssigned && b.statusCode != TransportStatus.partiallyAssigned) {
                                return -1;
                            } else if (a.statusCode != TransportStatus.partiallyAssigned && b.statusCode == TransportStatus.partiallyAssigned) {
                                return 1;
                            }

                            if (a.statusCode == TransportStatus.assigned && b.statusCode != TransportStatus.assigned) {
                                return -1;
                            } else if (a.statusCode != TransportStatus.assigned && b.statusCode == TransportStatus.assigned) {
                                return 1;
                            }

                            if (a.statusCode == TransportStatus.initiated && b.statusCode != TransportStatus.initiated) {
                                return -1;
                            } else if (a.statusCode != TransportStatus.initiated && b.statusCode == TransportStatus.initiated) {
                                return 1;
                            }

                            return 0;
                        });

                        this.albaranes = [];
                        this.albaranes = registros;
                    }, error: error => {
                        this.messageErrorGetAlbaranes = error.error.message;
                        this.showAlertErrorGetAlbaranes = true;
                        this.albaranes = [];
                    }
                }
            )
        }
    }

    changeToken() {
        let selectedTuition = this.selectedTuition;
        let tuitonSearched = this.matriculas.find(function (objeto) {
            return objeto.matricula === selectedTuition;
        });

        if (tuitonSearched) {
            this.auth.chengeTokenByTuiton(this.selectedTuition, tuitonSearched.idXofer).subscribe(
                {
                    next: async next => {
                        localStorage.setItem('token', next.token);
                        localStorage.setItem('matricula', this.selectedTuition);
                        await this.ngOnInit();
                    }
                }
            )
        }
    }

    async clearFilters() {
        this.albaranes = [];
        this.inputFiltroAlbaran = "";
        this.inputFiltroAlbaranDate = "";
        this.boolHiddeBtnClearFilters = !this.boolHiddeBtnClearFilters;
        await this.ngOnInit();
    }

    vaciarInputDate() {
        this.inputFiltroAlbaranDate = "";
    }

    closeAlertError() {
        this.showAlertErrorGetAlbaranes = false;
    }

    async advanceAlbaran(): Promise<void> {
        this.showAdvanceAlbaran = false;
        this.showCardAdvanceAlbaran = false;
        this.loaderShow = true;

        try {
            let albarans = await lastValueFrom(this.albaranService.getAdvanceAlbaran(this.albaranId));

            this.advancedAlbaran = <Albaran>albarans;

            if (this.advancedAlbaran.idAlbara) {
                this.showCardAdvanceAlbaran = true;
                this.showCameraAdvanceAlbarans = false;
            } else {
                this.showCameraAdvanceAlbarans = false;
                this.showMessageAdvancedAlbaranNotFound = true;
            }

            this.loaderShow = false;
            this.showAdvanceAlbaran = true;
        } catch (error) {
            this.loaderShow = false;
        }
    }

    async saveAdvancedAlbaran(idAlbara: string): Promise<void> {
        let oldArray: any = localStorage.getItem("advancedAlbarans");

        if (oldArray) {
            let newArray = JSON.parse(oldArray);

            newArray.push(idAlbara);

            localStorage.removeItem("advancedAlbarans")
            localStorage.setItem("advancedAlbarans", JSON.stringify(newArray));
        } else {
            let newArray = [idAlbara];

            localStorage.setItem("advancedAlbarans", JSON.stringify(newArray));
            localStorage.setItem("dateAdvancedAlbarans", this.inputFiltroAlbaranDate);
        }

        this.showMessageAdvancedAlbaranNotFound = false;
        this.showCameraAdvanceAlbarans = true;
        this.showCardAdvanceAlbaran = false;
        this.albaranId = "";
        this.showAdvanceAlbaran = false;
        await this.ngOnInit();
    }

    retryFoundAdvancedAlbaran(): void {
        this.showMessageAdvancedAlbaranNotFound = false;
        this.showCameraAdvanceAlbarans = true;
        this.showCardAdvanceAlbaran = false;
        this.albaranId = "";
    }

    openModalAdvanceAlbaran(): void {
        this.showAdvanceAlbaran = true;
        this.showCameraAdvanceAlbarans = true;
    }

    closeModalAdvanceAlbaran(): void {
        this.albaranId = "";
        this.showCardAdvanceAlbaran = false;
        this.showCameraAdvanceAlbarans = false;
        this.showMessageAdvancedAlbaranNotFound = false;
        this.showAdvanceAlbaran = false;
    }

    //LECTOR QR

    ngDoCheck() {
        this.deviceCurrent = this.qrDriver.deviceCurrent;
        this.deviceSelected = this.qrDriver.deviceSelected;
        this.availableDevices = this.qrDriver.availableDevices;
        this.enabled = this.qrDriver.enabled;
    }

    browserError(error: any) {
        this.qrDriver.browserError(error);
    }

    async ionViewWillEnter() {
        this.enabled = true;
        await this.start();
    }

    async start() {
        this.qrDriver.start().then(async (code) => {
            await this.saveResult(code);
        }).finally(async () => {
            await this.start();
        });
    }

    async ionViewWillLeave() {
        this.enabled = false;
        await this.close();
    }

    async close() {
        await this.qrDriver.close();
    }

    async checkBrowser(code: string) {
        if (code && this.qrDriver.check(code)) {
            if (!this.procesing) {
                await this.saveResult(code).catch(() => {
                });
            }
        } else if (code && !this.qrDriver.check(code)) {
            const toast = await this.toast.create({
                message: 'Error de lectura',
                color: 'danger',
                position: 'bottom',
                translucent: true,
                duration: 2000
            });
            await toast.present();
        }
    }

    async saveResult(code: any) {
        console.log(code);

        this.albaranId = code;
        await this.advanceAlbaran();

        return new Promise(async (resolve, reject) => {
            //await this.router.navigate(['/albaranes', code]);
        });
    }

    flashCheck() {
        this.showCheck = true;

        setTimeout(() => {
            this.showCheck = false;
        }, 3000);
    }

    flashFail() {
        this.showFail = true;

        setTimeout(() => {
            this.showFail = false;
        }, 3000);
    }

    onCamerasFound(devices: MediaDeviceInfo[]): void {
        this.qrDriver.onCamerasFound(devices);
    }

    onDeviceSelectChange(selected: MediaDeviceInfo) {
        this.qrDriver.onDeviceSelectChange(selected);
    }

    onDeviceChange(device: MediaDeviceInfo) {
        this.qrDriver.onDeviceChange(device);
    }

    onHasPermission(has: boolean) {
        this.qrDriver.onHasPermission(has);
    }


    onTorchCompatible(isCompatible: boolean): void {
        this.qrDriver.onTorchCompatible(isCompatible);
    }

    toggleTorch(): void {
        this.qrDriver.toggleTorch();
    }

    toggleTryHarder(): void {
        this.qrDriver.toggleTryHarder();
    }

    isDifferentDate(date:string){
        // Dividir la fecha
        const [year, month, day] = date.split('-').map(Number);

        if (isNaN(year) || isNaN(month) || isNaN(day)) {
            return true;//throw new Error("La fecha contiene valores no válidos.");
        }

        if (!year || !month || !day) {
            return true;// throw new Error('Fecha inválida o formato incorrecto');
        }

        // Crear la fecha asegurándote del formato
        const inputDate = new Date(year, month - 1, day);

        // Validar que sea una fecha válida
        if (isNaN(inputDate.getTime())) {
            return true;//throw new Error('La fecha no es válida');
        }

        const today = new Date();
        today.setHours(0, 0, 0, 0);

        return inputDate != today
    }
}
