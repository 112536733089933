import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component } from '@angular/core';
import { AuthService } from './services/auth.service';
import { Router } from "@angular/router";
import { DbService } from "./services/db.service";
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { filter, map } from 'rxjs';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: false
})
export class AppComponent implements AfterViewInit, AfterViewChecked {
    title = 'xofers';
    loaded: boolean = false;
    isLogged: boolean = false;
    latitude: number = 0;
    longitude: number = 0;

    constructor(
        private _auth: AuthService,
        private router: Router,
        private dbService: DbService,
        private swUpdate: SwUpdate,
        private cdRef: ChangeDetectorRef
    ) {
        if (this.swUpdate.isEnabled) {
            this.swUpdate.versionUpdates.pipe(
                filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
                map(evt => ({
                    type: 'UPDATE_AVAILABLE',
                    current: evt.currentVersion,
                    available: evt.latestVersion,
                }))
            ).subscribe(() => {
                if (confirm('Hay una nueva versión disponible. ¿Deseas actualizar?')) {
                    this.updateToNewVersion();
                }
            });
        }
    }

    ngAfterViewChecked()
    {
        this.cdRef.detectChanges();
    }

    async ngAfterViewInit() {
        if (!this.dbService.uploadingImages) {
            setInterval(() => {
                this.dbService.existsImages().then(images => {
                    if (images.length > 0) {
                        this.dbService.uploadPhotos();
                    } else {
                        //console.log("no hay nada");
                    }
                }).catch(err => {
                    console.error('Error al obtener las imágenes:', err);
                });
            }, 60000);
        }

        if (this._auth.check()) {
            this.isLogged = true;
            if(window.location.pathname.indexOf('/albaranes') === -1)
                await this.router.navigate(['/albaranes']);
        } else {
            this.isLogged = false;
        }

        this.loaded = false;

        navigator.geolocation.getCurrentPosition((values) => {
            this.latitude = values?.coords?.latitude;
            this.longitude = values?.coords?.longitude;
        }, function (error) {
            switch (error.code) {
                case error.PERMISSION_DENIED:
                    alert("El usuario denegó el permiso para la Geolocalización.")
                    break;
                case error.POSITION_UNAVAILABLE:
                    alert("La ubicación no está disponible.")
                    break;
                case error.TIMEOUT:
                    alert("Se ha excedido el tiempo para obtener la ubicación.")
                    break;
                default:
                    alert("Se ha producido un error.")
                    break;
            }
        });
    }
    private updateToNewVersion() {
        window.location.reload();
    }
}
