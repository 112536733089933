import { Component } from '@angular/core';
import { Settings } from '../../data/Settings';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    standalone: false
})
export class FooterComponent {

    protected readonly Settings = Settings;
}
