import { Component } from '@angular/core';
import { global } from "../../../services/global";
import { Location } from "@angular/common";
import { UploadService } from "../../../services/upload.service";
import { ActivatedRoute } from "@angular/router";

@Component({
    selector: 'app-albaran-images',
    templateUrl: './albaran-images.component.html',
    styleUrls: ['./albaran-images.component.scss'],
    standalone: false
})
export class AlbaranImagesComponent {

    name = "";

    url = global.getUrl();

    showLoader: boolean = false;

    documents: any;
    idAlbara: any;

    constructor(
        private location: Location,
        private uploadService: UploadService,
        private route: ActivatedRoute
    ) {
    }

    ngOnInit(): void {
        this.route.params.subscribe(
            (params) => {
                this.idAlbara = params['idAlbara'];
                this.name = "Ver imágenes salidas y llegadas - " + params['idAlbara'];
            }
        );

        this.showLoader = true;

        this.uploadService.getImages(this.idAlbara).subscribe(
            img => {
                this.documents = img.documents;
                this.showLoader = false;
            }
        )
    }

}
