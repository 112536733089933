import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-splash',
    templateUrl: './splash.component.html',
    styleUrls: ['./splash.component.scss'],
    standalone: false
})
export class SplashComponent implements OnInit {

    windowWidth: string | undefined;
    showSplash = true;

    ngOnInit(): void {
        setTimeout(() => {
            this.windowWidth = "-" + window.innerWidth + "px";

            setTimeout(() => {
                this.showSplash = !this.showSplash;
            }, 500);
        }, 1500);
    }
}
