import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { global } from "./global";
import { AuthService } from "./auth.service";

@Injectable({
    providedIn: 'root'
})
export class AlbaranService {

    url: string

    modeTransport: any;
    tipusTransport: any;
    idRecollidaEntregaContenidor: any;

    constructor(
        private http: HttpClient,
        private auth: AuthService
    ) {
        this.url = global.getUrl();
    }


    getLastTransportDate(deliveryNoteId:any): Observable<any> {
        //console.log('Delivery Note', deliveryNoteId);
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/x-www-form-urlencoded')
            .set('Authorization', this.auth.getToken());

        return this.http.get(global.getUrl() + 'xofers/getLastTransportDateByDeliveryNote?id=' + deliveryNoteId, {headers: headers});
    }
    getAlbaranes(advancedAlbarans: any): Observable<any> {
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/x-www-form-urlencoded')
            .set('Authorization', this.auth.getToken());

        if (advancedAlbarans !== null)
            return this.http.get(global.getUrl() + 'xofers/getalbaranes?advancedAlbarans=' + advancedAlbarans, {headers: headers});
        else
            return this.http.get(global.getUrl() + 'xofers/getalbaranes', {headers: headers});
    }

    getAlbaranesFiltrados(fecha: any, idAlbaran: any): Observable<any> {
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/x-www-form-urlencoded')
            .set('Authorization', this.auth.getToken());

        return this.http.get(global.getUrl() + 'xofers/getalbaranes?fecha=' + fecha + '&idAlbara=' + idAlbaran, {headers: headers});
    }

    getChecklist(idAlbara: number, idTransport: any): Observable<any> {
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/x-www-form-urlencoded')
            .set('Authorization', this.auth.getToken());

        return this.http.post(global.getUrl() + 'xofers/getchecklist', 'idAlbara=' + idAlbara + '&idTransport=' + idTransport, {headers: headers});
    }

    sendChecks(lat: number, long: number, idCheck: number, idLloc: number, idLlocDestiOrigen: number, idAlbara: number, idTransport: any): Observable<any> {
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/x-www-form-urlencoded')
            .set('Authorization', this.auth.getToken());

        let params = {
            latitude: lat,
            longitude: long,
            idCheck: idCheck,
            idLloc: idLloc,
            idLlocDestiOrigen: idLlocDestiOrigen,
            idAlbara: idAlbara,
            idTransport: idTransport,
        };
        return this.http.post(global.getUrl() + 'xofers/savechecklist', 'check=' + JSON.stringify(params), {headers: headers});
    }

    sendIncidencia(incidencia: any, idAlbara: number, idTransport: number, idCheck: string): Observable<any> {
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/x-www-form-urlencoded')
            .set('Authorization', this.auth.getToken());

        return this.http.post(global.getUrl() + 'xofers/saveincidencia', 'incidencia=' + JSON.stringify(incidencia)
            + '&reference=' + idAlbara + '&idTransport=' + idTransport + '&idCheck=' + idCheck, {headers: headers});
    }

    getInfoAlbaran(idAlbara: number): Observable<any> {
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/x-www-form-urlencoded')
            .set('Authorization', this.auth.getToken());

        return this.http.get(global.getUrl() + 'xofers/getinfoalbaran?idAlbara=' + idAlbara, {headers: headers});
    }

    getNameXofer() {
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/x-www-form-urlencoded')
            .set('Authorization', this.auth.getToken());

        return this.http.get(global.getUrl() + 'xofers/getnomxofer', {headers: headers});
    }

    checkMasAlbaranesLlegadas(albaranesLlegadas: any[]) {
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/x-www-form-urlencoded')
            .set('Authorization', this.auth.getToken());

        return this.http.post(global.getUrl() + 'xofers/checkMasAlbaranesLlegadas', 'body=' + JSON.stringify(albaranesLlegadas), {headers: headers});
    }

    checkMasAlbaranesSPC(transportId : any, idAlbara: any , albaranes: any[], lloc: any) {
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/x-www-form-urlencoded')
            .set('Authorization', this.auth.getToken());

        let params = {
            albaranes: albaranes
        };

        return this.http.post(global.getUrl() + 'xofers/checkMasAlbaranesLlegadas', 'body=' + JSON.stringify(params) + '&lloc=' + lloc + '&transport=' + transportId + '&reference=' + idAlbara, {headers: headers});
    }

    checkMasAlbaranesSPD(albaranes: any[]) {
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/x-www-form-urlencoded')
            .set('Authorization', this.auth.getToken());

        let params = {
            albaranes: albaranes
        };

        return this.http.post(global.getUrl() + 'xofers/checkMasAlbaranesLlegadas', 'body=' + JSON.stringify(params), {headers: headers});
    }

    getListFirstDestinyXofer(dateTransport: any) {
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/x-www-form-urlencoded')
            .set('Authorization', this.auth.getToken());

        return this.http.get(global.getUrl() + 'xofers/getlistfirstdestinyxofer?dateTransport=' + dateTransport, {headers: headers});
    }

    saveFirstDestinyXofer(firstDestiny: any, tuiton: any, dateTransport: any) {
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/x-www-form-urlencoded')
            .set('Authorization', this.auth.getToken());

        return this.http.post(global.getUrl() + 'xofers/savefirstdestiny', 'destiny=' + firstDestiny + '&tuiton=' + tuiton
            + '&dateTransport=' + dateTransport, {headers: headers});
    }

    checkBarcodeAWB(barcode: any, idAlbara: any, isPartial: any, isTotal: any, isScanned: any, isManual: any, idTransport: any) {
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/x-www-form-urlencoded')
            .set('Authorization', this.auth.getToken());

        return this.http.get(global.getUrl() + 'xofers/checkbarcodeawb?barcode=' + barcode + "&idAlbara=" + idAlbara + "&isPartial=" + isPartial + "&isTotal="
            + isTotal + "&isScanned=" + isScanned + "&isManual=" + isManual + "&idTransport=" + idTransport, {headers: headers});
    }

    endBarcodeTask(checkListTaskDriverId: number, deliveryNoteId:number , transportId:number) {
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/x-www-form-urlencoded')
            .set('Authorization', this.auth.getToken());

        return this.http.get(global.getUrl() + 'xofers/endBarcodeTask?checklist=' + checkListTaskDriverId + "&deliveryNote=" + deliveryNoteId + "&transport=" + transportId, {headers: headers});
    }

    getLinkedAlbarans(idCheck: any, idAlbara: any) {
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/x-www-form-urlencoded')
            .set('Authorization', this.auth.getToken());

        return this.http.get(global.getUrl() + 'xofers/getlinkedalbarans?idCheck=' + idCheck + "&idAlbara=" + idAlbara, {headers: headers});
    }

    getInfoByCodeIATA(codeIATAtoCheck: any) {
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/x-www-form-urlencoded')
            .set('Authorization', this.auth.getToken());

        return this.http.get(global.getUrl() + 'xofers/getinfobycodeiata?codeIATA=' + codeIATAtoCheck, {headers: headers});
    }

    checkBultosDestinyAWB(isChecked: any, idAlbara: any , idTransport : any) {
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/x-www-form-urlencoded')
            .set('Authorization', this.auth.getToken());

        return this.http.post(global.getUrl() + 'xofers/checkbultosdestinyawb', 'isChecked=' + isChecked + '&reference=' + idAlbara + '&idTransport=' + idTransport, {headers: headers});
    }

    getAdvanceAlbaran(albaranId: string) {
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/x-www-form-urlencoded')
            .set('Authorization', this.auth.getToken());

        return this.http.get(global.getUrl() + 'xofers/getadvancealbarans?albaranId=' + albaranId, {headers: headers});
    }

    changeStatusPreFinished(idAlbara: string) {
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/x-www-form-urlencoded')
            .set('Authorization', this.auth.getToken());

        return this.http.post(global.getUrl() + 'xofers/changestatusprefinished', 'reference=' + idAlbara, {headers: headers});
    }

}
