import { Component, Input } from '@angular/core';
import { Location } from "@angular/common";
import { Router } from "@angular/router";
import { AlbaranListComponent } from "../albaran/albaran-list/albaran-list.component";

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
    standalone: false
})
export class NavbarComponent {

    @Input() nameSection: string;
    @Input() nameXofer: string;
    @Input() back?: any[];
    constructor(
        private location: Location,
        private router: Router,
        private albaranListComponent: AlbaranListComponent,
    ) {
        this.nameSection = "";
        this.nameXofer = "";
        this.back = [];
    }

    shouldHideButton(): boolean {
        const currentRoute = this.router.url;
        const pageToHideButton = '/albaranes';
        const pageToHideButton2 = '/albaranes/' + this.albaranListComponent.valorRecibido;

        if (currentRoute === pageToHideButton) {
            return currentRoute === pageToHideButton;
        } else if (currentRoute === pageToHideButton2) {
            return currentRoute === pageToHideButton2;
        } else {
            return false;
        }
    }

    shouldHideButtonRefresh(): boolean {
        const currentRoute = this.router.url;
        const pageToHideButton2 = '/albaranes/' + this.albaranListComponent.valorRecibido;

        return currentRoute === pageToHideButton2;
    }

    signOut() {
        localStorage.removeItem('token');
        window.location.reload();
    }

    async goBack() {
        if (Array.isArray(this.back) && this.back.length > 0) {
            try {
                await this.router.navigate(this.back);
            } catch (error) {
                //console.error('Error while navigating:', error);
                this.location.back();
            }
        } else {
            this.location.back();
        }
    }

    async refreshNav(): Promise<void> {
        await this.router.navigate(['/albaranes']);
    }
}
