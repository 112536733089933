import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AlbaranListComponent } from './components/albaran/albaran-list/albaran-list.component';
import { NotFoundComponent } from './components/error/not-found/not-found.component';
import { AlbaranContentComponent } from "./components/albaran/albaran-content/albaran-content.component";
import { AlbaranUploadPhotoComponent } from "./components/albaran/albaran-upload-photo/albaran-upload-photo.component";
import { AlbaranProgressComponent } from "./components/albaran/albaran-progress/albaran-progress.component";
import { LoginComponent } from "./components/login/login.component";
import { QrReaderPage } from "./components/albaran/qr-reader/qr-reader.page";
import { AlbaranImagesComponent } from "./components/albaran/albaran-images/albaran-images.component";

const routes: Routes = [
    {path: 'login', component: LoginComponent},
    {path: 'lector-qr', component: QrReaderPage},
    {path: 'albaranes', component: AlbaranListComponent},
    {path: 'albaranes/:idAlbara', component: AlbaranListComponent},
    { path: 'albaran/:idAlbara', component: AlbaranContentComponent },
    { path: 'albaran/:idAlbara/:idTransport', component: AlbaranContentComponent },
    {path: 'upload-photo/:idAlbara/:idTransport', component: AlbaranUploadPhotoComponent},
    {path: 'show-images/:idAlbara/:idTransport', component: AlbaranImagesComponent},
    {path: 'progress/:idAlbara/:idTransport', component: AlbaranProgressComponent},
    {path: '**', component: NotFoundComponent}
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'})],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
