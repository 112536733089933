import { Component } from '@angular/core';
import { User } from "../../models/user";
import { AuthService } from "../../services/auth.service";
import { Router } from "@angular/router";
import { AppComponent } from "../../app.component";

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    standalone: false
})
export class LoginComponent {
    error: boolean = false;
    loading: boolean = false;
    success: boolean = false;
    logged: boolean = false;

    user: User;
    identity: string = "";
    token: string = "";
    status: string = "";

    constructor(
        private auth: AuthService,
        private router: Router,
        private appComponent: AppComponent,
    ) {
        this.user = new User(1, '', '', 'ROLE_USER', '', '', '', '', '')
    }

    login() {
        this.loading = true;
        this.error = false;
        this.success = false;
        localStorage.removeItem('matricula');

        this.auth.isLogged(this.user, false).subscribe(
            {
                next: next => {
                    if (next.status != 'error') {
                        this.status = 'success';
                        this.token = next.token;

                        this.auth.isLogged(this.user, true).subscribe(
                            {
                                next: success => {
                                    if (success.status != 'error') {
                                        this.identity = success.identity;

                                        localStorage.setItem('token', this.token);
                                        localStorage.setItem('identity', JSON.stringify(this.identity));

                                        this.loading = false;
                                        this.error = false;
                                        this.success = true;

                                        this.appComponent.isLogged = true;
                                        this.auth.showQuestionDestiny = true;
                                        this.router.navigate(['/albaranes']);
                                    } else {
                                        this.loading = false;
                                        this.error = true;
                                        this.success = false;
                                        this.status = 'error';
                                    }
                                }, error: error => {
                                    this.loading = false;
                                    this.error = true;
                                    this.success = false;
                                    this.status = 'error';
                                }
                            }
                        )
                    } else {
                        this.loading = false;
                        this.error = true;
                        this.success = false;
                        this.status = 'error';
                    }
                }, error: error => {
                    this.loading = false;
                    this.error = true;
                    this.success = false;
                    this.status = 'error';
                }
            }
        );
    }
}
