import { Component, DoCheck, Inject, ViewChild } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { LoadingController, Platform, ToastController, ViewWillEnter, ViewWillLeave } from '@ionic/angular';
import { DOCUMENT, Location } from '@angular/common';
import { ZXingScannerComponent } from '@zxing/ngx-scanner';
import { BarcodeFormat } from '@zxing/library';
import { QrDriver } from '../../../drivers/qr.driver';
import { QrServiceService } from "../../../services/qr-service.service";

@Component({
    selector: 'app-qr-reader',
    templateUrl: './qr-reader.page.html',
    styleUrls: ['./qr-reader.page.scss'],
    standalone: false
})
export class QrReaderPage implements ViewWillEnter, DoCheck, ViewWillLeave {

    @ViewChild('scanner', {static: true}) scanner: ZXingScannerComponent | undefined;

    public inApp = this.platform.is('mobileweb') || this.platform.is('desktop') ? 'browser' : 'app';

    public procesing = false;
    public enabled = true;

    name = "Lector QR";

    formatsEnabled: BarcodeFormat[] = [
        BarcodeFormat.CODE_128,
        BarcodeFormat.DATA_MATRIX,
        BarcodeFormat.EAN_13,
        BarcodeFormat.QR_CODE
    ];

    availableDevices: MediaDeviceInfo[] | undefined;
    deviceCurrent: MediaDeviceInfo | undefined;
    deviceSelected?: MediaDeviceInfo;

    showCheck = false;

    showFail = false;

    constructor(
        private toast: ToastController,
        private router: Router,
        private platform: Platform,
        private qrDriver: QrDriver,
        @Inject(DOCUMENT) private document: Document
    ) {
        this.enabled = this.qrDriver.enabled;
    }

    ngDoCheck() {
        this.deviceCurrent = this.qrDriver.deviceCurrent;
        this.deviceSelected = this.qrDriver.deviceSelected;
        this.availableDevices = this.qrDriver.availableDevices;
        this.enabled = this.qrDriver.enabled;
    }

    async browserError(error: any) {
        await this.qrDriver.browserError(error);
    }

    async ionViewWillEnter() {
        this.enabled = true;
        await this.start();
    }

    async start() {
        this.qrDriver.start().then(async (code) => {
            await this.saveResult(code);
        }).finally(async () => {
            await this.start();
        });
    }

    async ionViewWillLeave() {
        this.enabled = false;
        await this.close();
    }

    async close() {
        await this.qrDriver.close();
    }

    async checkBrowser(code: string) {
        /*
        const toast = await this.toast.create({
            message: 'Code: ' + code,
            color: 'info',
            position: 'bottom',
            translucent: true,
            duration: 2000
        });
        await toast.present();
         */

        if (code) {
            if (!this.procesing) {
                await this.saveResult(code);
            }
        } else if (code && !this.qrDriver.check(code)) {
            const toast = await this.toast.create({
                message: 'Error de lectura',
                color: 'danger',
                position: 'bottom',
                translucent: true,
                duration: 2000
            });
            await toast.present();
        }
    }

    async saveResult(code: any) {
        let idAlbara = code;
        if (idAlbara.split("\n").length > 1) {
            idAlbara = idAlbara.split("\n")[1];
        }
        /*
        const toast = await this.toast.create({
            message: 'CODE: '+code+' | ID: ' + idAlbara,
            color: 'info',
            position: 'bottom',
            translucent: true,
            duration: 2000
        });
        await toast.present();
         */
        await this.router.navigate(['/albaranes', idAlbara.trim()]);
    }

    onCamerasFound(devices: MediaDeviceInfo[]): void {
        this.qrDriver.onCamerasFound(devices);
    }

    onDeviceSelectChange(selected: MediaDeviceInfo) {
        this.qrDriver.onDeviceSelectChange(selected);
    }

    onDeviceChange(device: MediaDeviceInfo) {
        this.qrDriver.onDeviceChange(device);
    }

    onHasPermission(has: boolean) {
        this.qrDriver.onHasPermission(has);
    }


    onTorchCompatible(isCompatible: boolean): void {
        this.qrDriver.onTorchCompatible(isCompatible);
    }

}
