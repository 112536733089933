<app-navbar [nameSection]="name"></app-navbar>

<header class="mt-3">
    <div class="cameras">
        <span (click)="onDeviceSelectChange(device)" *ngFor="let device of availableDevices; let i = index"
              [innerHTML]="i+1" [ngClass]="{selected: device && deviceSelected && device?.deviceId === deviceSelected.deviceId}"
              class="camera"></span>
    </div>
</header>

<div class="qr-scanner">
    <div class="box">
        <div class="line"></div>
        <div class="angle"></div>
    </div>
</div>

<div>
    <zxing-scanner #scanner
                   [enable]="true"
                   [(device)]="deviceSelected"
                   (camerasFound)="onCamerasFound($event)"
                   (camerasNotFound)="browserError('No existen camaras disponibles')"
                   (deviceChange)="onDeviceChange($event)"
                   (permissionResponse)="onHasPermission($event)"
                   (scanSuccess)="checkBrowser($event)"
                   (torchCompatible)="onTorchCompatible($event)"
                   [formats]="formatsEnabled"></zxing-scanner>
</div>

<div [ngClass]="{show: showCheck}" class="success-checkmark">
    <div class="check-icon">
        <span class="icon-line line-tip"></span>
        <span class="icon-line line-long"></span>
        <div class="icon-circle"></div>
        <div class="icon-fix"></div>
    </div>
</div>

<div [ngClass]="{show: showFail}" class="fail-checkmark">
    <div class="fail-icon">
        <span class="icon-line line-tip"></span>
        <span class="icon-line line-long"></span>
        <div class="icon-circle"></div>
        <div class="icon-fix"></div>
    </div>
</div>
