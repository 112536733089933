import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { Checklist } from "../../../models/checklist";
import { AlbaranService } from "../../../services/albaran.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Incidencia } from "../../../models/incidencia";
import { UploadService } from "../../../services/upload.service";
import { Lloc } from "../../../models/lloc";
import { ZXingScannerComponent } from "@zxing/ngx-scanner";
import { BarcodeFormat } from "@zxing/library";
import { ToastController } from "@ionic/angular";
import { QrDriver } from "../../../drivers/qr.driver";
import { lastValueFrom } from "rxjs";
import { DbService } from "../../../services/db.service";
import { Image } from "../../../models/Image";
import { CheckListTaskDriver } from "../../../data/CheckListTaskDriver";
import { TransportMode } from "../../../data/TransportMode";
import { TransportStatus } from "../../../data/TransportStatus";

@Component({
    selector: 'app-albaran-progress',
    templateUrl: './albaran-progress.component.html',
    styleUrls: ['./albaran-progress.component.scss'],
    standalone: false
})
export class AlbaranProgressComponent implements AfterViewInit {

    name: string = "";

    @ViewChild('buttonModal') buttonModal!: ElementRef;
    @ViewChild('individualPhotoLlegadasSalidasModal') individualPhotoButtonModal!: ElementRef;
    @ViewChild('modalMandatoryPhotoSRCB') modalMandatoryPhotoSRCB!: ElementRef;
    @ViewChild('clickIncidencia') clickIncidencia!: ElementRef;
    @ViewChild('clickUploadPOD') clickUploadPOD!: ElementRef;

    /* VARIABLES COMPROBACIÓN AWB */
    @ViewChild('scanner', {static: true}) scanner: ZXingScannerComponent | undefined;
    @ViewChild('inputCheckBarcodeManually') inputCheckBarcodeManually: ElementRef | undefined;

    public procesing = false;
    public enabled = true;

    formatsEnabled: BarcodeFormat[] = [
        BarcodeFormat.CODE_128,
        BarcodeFormat.CODE_39,
        BarcodeFormat.CODE_93,
        BarcodeFormat.EAN_13,
        BarcodeFormat.EAN_8,
        BarcodeFormat.CODABAR,
    ];

    availableDevices?: MediaDeviceInfo[];
    deviceCurrent?: MediaDeviceInfo;
    deviceSelected?: MediaDeviceInfo;

    /* VARIABLES COMPROBACIÓN AWB */

    checkLists: Checklist[] = [];
    incidencia: Incidencia = new Incidencia();
    albaranesLlegadas: any[] = [];
    albaranesSPC: any[] = [];
    llocsAlbaranSPC: Lloc[] = [];
    albaranesLLPD: any[] = [];
    albaranesSPD: any[] = [];
    albaranesLLPP: any[] = [];
    albaranesSPP: any[] = [];
    llocsAlbaranSPP: Lloc[] = [];
    llocsLinkedAlbaransAWB: any[] = [];

    idAlbara: any;
    idTransport: any;
    base64: any;
    base64image: any;
    file: any;
    latitude: any;
    longitude: any;
    idCheck: any;
    idLloc: any;
    pes: any;
    bultos: any;
    volum: any;
    idDesti: any;
    nomOrigen: any;
    idOrigen: any;
    tipusTransport: any;
    modeTransport: any;
    idRecollidaEntregaContenidor: any;
    imageChangedEvent: any = '';
    croppedImage: any = '';

    albaranesMarcadosLlegadas: any[] = [];
    extraBillingsPhotos: any[] = [];
    extraBillingsPhotosPOD: any[] = [];
    llocsMarcadosSPC: any;

    uploadPhotoAlbaran: boolean = false;
    buttonHidden: boolean = true;

    scannSuccess: boolean = false;
    boolUploadLlegadaSalida: boolean = false;

    showListLlegadasLLPC: boolean = false;

    showListNextPointsSPC: boolean = false;
    showListNextPointsSPCaux: boolean = false;
    showListExtraPhotosSPC: boolean = false;
    showListMoreAlbaransSPC: boolean = false;

    showListLLPD: boolean = false;

    showListMoreAlbaransSPD: boolean = false;
    showListPhotosPodSPD: boolean = false;

    showListLLPP: boolean = false;

    showListSPP: boolean = false;
    showListNextPointsSPP: boolean = false;

    showMandatoryPhoto: boolean = false;
    mandatoryPhotoDone: boolean = false;

    showCheckAWB: boolean = false;
    showCheckAWBScanner: boolean = false;
    showCheckAWBDone: boolean = false;
    showCheckAWBManually: boolean = false;
    showCheckBultosAWB: boolean = false;
    showCheckDestinyAWB: boolean = false;
    showCheckDestinyIATA: boolean = false;
    checkedAppropiate: boolean = false;
    checkedAppropiateDestiny: boolean = false;
    checkedAppropiateDestinyIATA: boolean = false;
    isNotAppropiate: boolean = false;
    isNotAppropiateDestiny: boolean = false;
    waitCheck: boolean = false;
    isTotalClicked: number = 0;
    isPartialClicked: number = 0;
    isTotal: number = 0;
    isPartial: number = 0;
    isTotalSuccess: boolean = false;
    $successSave: boolean = false;
    isPartialSuccess: boolean = false;
    isCheckedAWB: number = 0;
    isCheckedBultosDestinyAWB: number = 0;

    messageCheckAWBDone: string = '';
    idMessageCheckAWBDone: string = '';
    originNamesLinkedAlbarans: string = '';
    codeIATAtoCheck: string = '';
    nomAeroport: string = '';
    municipiAeroport: string = '';
    paisAeroport: string = '';

    showLoader: boolean = false;
    isCroppedImage: boolean = false;

    showAlertErrorUploadPhotoAlbaran: boolean = false;
    messageErrorAlbaran: string = "";

    showAlertSuccessUploadPhotoAlbaran: boolean = false;
    messageSuccessAlbaran: string = "";

    showAlertCheckTardio: boolean = false;
    messageCheckTardio: string = "";

    showAlertSuccessScannBarcode: boolean = false;
    messageSuccessScannBarcode: string = "";

    showObligationPhoto: boolean = false;
    showObligationPhotoPOD: boolean = false;
    checkClick: boolean = false;
    checkClickPOD: boolean = false;

    showListMoreAlbarans: boolean = false;

    billingsPhotosDone: boolean = false;
    billingsPhotosPODDone: boolean = false;
    isMultitasking: boolean = false;

    showAlertUploadFotoLLegaSalida: boolean = false;
    messageAlertUploadFotoLLegaSalida: string = "";

    showAlertPlaceToGo: boolean = false;
    messageAlertPlaceToGo: string = "";

    showAlertCreateChecks: boolean = false;
    messageAlertCreateChecks: string = "";

    showAlertAlbaranCompletedSECP: boolean = false;
    messageAlertAlbaranCompletedSECP: string = "";

    selectedCheckboxId: string | null = null;
    actionChecklist: string = "";

    isDeliveryNoteSplit: boolean = false;
    remainingPackages: number = 0;
    deliveryNoteId: number = 0;

    protected readonly CheckListTaskDriver = CheckListTaskDriver;

    constructor(
        private albaranService: AlbaranService,
        private route: ActivatedRoute,
        private uploadService: UploadService,
        private router: Router,
        private qrDriver: QrDriver,
        private toast: ToastController,
        private dbService: DbService,
    ) {
    }

    async ngAfterViewInit() {
        await this.reload();
    }

    async reload() {
        this.tipusTransport = this.albaranService.tipusTransport;
        this.modeTransport = this.albaranService.modeTransport;
        this.idRecollidaEntregaContenidor = this.albaranService.idRecollidaEntregaContenidor;

        await new Promise((resolve) => {

            this.route.params.subscribe(
                params => {
                    this.idAlbara = params['idAlbara'];
                    this.idTransport = params['idTransport'];
                    this.name = "Progreso albarán - " + params['idAlbara'];
                    resolve(true);
                }
            )
        });

        this.showLoader = true;

        this.albaranService.getChecklist(this.idAlbara, this.idTransport).subscribe(
            {
                next: checklist => {
                    this.checkLists = checklist;
                    for (let check of this.checkLists) {
                        if (check.incidenciaMarcada) {
                            for (let incidencia of check.incidencia) {
                                incidencia.incidenciaMinimizada = true;
                            }
                            check.incidenceDisabled = true;
                        }

                        if (check.checked === 1 && check.taskCode == CheckListTaskDriver.departureFromUnloadingPoint) {
                            this.uploadPhotoAlbaran = true;

                            if (this.checkClickPOD) {
                                this.showObligationPhotoPOD = true;
                                this.checkClickPOD = false;
                            }
                        }

                        if (check.taskCode == CheckListTaskDriver.departureFromLoadingPoint && check.checked === 1 && this.checkClick) {
                            this.showObligationPhoto = true;
                            this.checkClick = !this.checkClick;
                        }

                        this.idDesti = check.idDesti;
                        this.idOrigen = check.idOrigen;
                        this.pes = check.pes;
                        this.bultos = check.bultos;
                        this.volum = check.volum;

                        this.isTotalClicked = check.isTotal;
                        this.isPartialClicked = check.isPartial;
                        this.isTotal = check.isTotal;
                        this.isPartial = check.isPartial;
                        this.isCheckedAWB = check.isCheckedAWB;
                        this.isCheckedBultosDestinyAWB = check.isCheckedBultosDestinyAWB;
                        this.isDeliveryNoteSplit = check.isDeliveryNoteSplit;
                        this.remainingPackages = check.remainingPackages;
                        this.deliveryNoteId = check.reference

                        if (check.taskCode == CheckListTaskDriver.arrivalAtLoadingPoint || check.taskCode == CheckListTaskDriver.departureFromLoadingPoint) {
                            this.nomOrigen = check.nomDesc;
                        }
                    }

                    // Ordenar por fecha en dataCheck
                    this.checkLists.sort((a, b) => {
                        if (a.dataCheck === null && b.dataCheck === null) {
                            return 0;
                        } else if (a.dataCheck === null) {
                            return 1;
                        } else if (b.dataCheck === null) {
                            return -1;
                        } else {
                            const dateA = new Date(a.dataCheck);
                            const dateB = new Date(b.dataCheck);
                            if (dateA < dateB) return -1;
                            if (dateA > dateB) return 1;
                            return 0;
                        }
                    });

                    this.showLoader = false;
                },
                error: error => {
                    this.showAlertCreateChecks = true;
                    this.messageAlertCreateChecks = error.error.message;

                    this.showLoader = false;
                }
            }
        )
    }

    clickIncidecia(id: number) {
        this.checkLists.map(function (dato) {
            if (dato.id == id && !dato.incidenciaMarcada) {
                dato.incidenciaMarcada = true;
                dato.newIncidence = true;
            } else if (dato.id == id) {
                dato.incidenciaMarcada = false;
                dato.newIncidence = false;
            }

            return dato;
        });
    }

    private getMessageGeolocationError(error:GeolocationPositionError ){
        let errorMessage = '';

        if (error.code === 1) {
            errorMessage = "Permiso denegado. Habilite los servicios de geolocalización.";
        } else if (error.code === 2) {
            errorMessage = "No se ha podido determinar su posición.";
        } else if (error.code === 3) {
            errorMessage = "Tiempo de espera excedido. Por favor inténtelo de nuevo.";
        } else {
            errorMessage = `Error desconocido. Póngase en contacto con su administrador: {${error.message}}`;
        }
        return errorMessage;
    }
    clickCheck(event: any, nom: any, taskCode: any) {
        this.showLoader = true;

        let idLlocDestiOrigen = this.idOrigen

        if (taskCode === CheckListTaskDriver.departureFromLoadingPoint || taskCode === CheckListTaskDriver.departureFromUnloadingPoint) {
            idLlocDestiOrigen = this.idDesti;
        }

        const showPosition = (position:any)=>{
            let $this = this;
            $this.latitude = position.coords.latitude;
            $this.longitude = position.coords.longitude;
            $this.idCheck = event.target.id;
            $this.idLloc = event.target.name;

            $this.albaranService.sendChecks($this.latitude, $this.longitude, $this.idCheck, $this.idLloc, idLlocDestiOrigen, $this.idAlbara, $this.idTransport).subscribe(
                {
                    next: async next => {
                        await $this.reload();

                        $this.albaranesMarcadosLlegadas = [];

                        $this.albaranesLlegadas = next.albaransLLPC;
                        $this.showListLlegadasLLPC = $this.albaranesLlegadas.length !== 0;

                        $this.albaranesSPC = next.albaransSPC;
                        $this.llocsAlbaranSPC = next.llocsSPC;
                        $this.showListMoreAlbaransSPC = $this.albaranesSPC.length !== 0;
                        //Salida punto de carga
                        if (taskCode === CheckListTaskDriver.departureFromLoadingPoint) {
                            $this.checkClick = !$this.checkClick;

                            let newAlbaranMarcadoLLegada = {
                                idAlbara: $this.idAlbara,
                                reference: null,
                                idCheck: $this.idCheck,
                                latitude: $this.latitude,
                                longitude: $this.longitude,
                                lloc: $this.idLloc,
                                bultos: $this.bultos,
                                kg: $this.pes,
                                volumen: $this.volum,
                            };

                            $this.showListNextPointsSPCaux = $this.llocsAlbaranSPC.length !== 0;

                            if (!$this.showListMoreAlbaransSPC && $this.showListNextPointsSPCaux) {
                                $this.isMultitasking = true;
                                $this.showListExtraPhotosSPC = true;
                            } else if (!$this.showListMoreAlbaransSPC && !$this.showListNextPointsSPCaux) {
                                $this.isMultitasking = false;
                                $this.showListExtraPhotosSPC = true;
                            }

                            $this.albaranesMarcadosLlegadas.push(newAlbaranMarcadoLLegada);
                        }

                        $this.albaranesLLPD = next.albaransLLPD;
                        $this.showListLLPD = $this.albaranesLLPD.length !== 0;

                        $this.albaranesSPD = next.albaransSPD;
                        $this.showListMoreAlbaransSPD = $this.albaranesSPD.length !== 0;

                        // Salida punto de descarga
                        if (taskCode === CheckListTaskDriver.departureFromUnloadingPoint) {
                            $this.checkClickPOD = !$this.checkClickPOD;

                            let newAlbaranMarcadoLLegada = {
                                idAlbara: $this.idAlbara,
                                reference: null,
                                idCheck: $this.idCheck,
                                latitude: $this.latitude,
                                longitude: $this.longitude,
                                lloc: $this.idLloc,
                                bultos: $this.bultos,
                                kg: $this.pes,
                                volumen: $this.volum,
                            };

                            if (!$this.showListMoreAlbaransSPD) {
                                $this.showListPhotosPodSPD = true;
                            }

                            $this.albaranesMarcadosLlegadas.push(newAlbaranMarcadoLLegada);
                        }

                        $this.albaranesLLPP = next.albaransLLPP;
                        $this.showListLLPP = $this.albaranesLLPP.length !== 0;

                        $this.albaranesSPP = next.albaransSPP;
                        $this.llocsAlbaranSPP = next.llocsSPP;
                        $this.showListSPP = $this.albaranesSPP.length !== 0;
                        $this.showListNextPointsSPP = $this.llocsAlbaranSPP.length !== 0;

                        //Salida punto de paso
                        if (taskCode == CheckListTaskDriver.departureFromWaypoint) {
                            let newAlbaranMarcadoLLegada = {
                                idAlbara: $this.idAlbara,
                                reference: null,
                                idCheck: $this.idCheck,
                                latitude: $this.latitude,
                                longitude: $this.longitude,
                                lloc: $this.idLloc,
                            };

                            $this.showListMoreAlbarans = true;
                            $this.albaranesMarcadosLlegadas.push(newAlbaranMarcadoLLegada);
                        }

                        //Salida recogida contenedor vacío
                        if (taskCode == CheckListTaskDriver.departureFromEmptyContainerPickup) {
                            $this.showMandatoryPhoto = true;
                        }

                        //Salida entrega contenedor vacío.
                        if (taskCode == CheckListTaskDriver.departureFromEmptyContainerDelivery) {
                            $this.showAlertAlbaranCompletedSECP = true;
                            $this.messageAlertAlbaranCompletedSECP = "Albarán finalizado correctamente.";
                        }

                        if (next.message) {
                            $this.messageCheckTardio = next.message;
                            $this.showAlertCheckTardio = !$this.showAlertCheckTardio;
                        }

                        $this.showLoader = false;
                    }, error: error => {
                        $this.messageCheckTardio = error.error.message;
                        $this.showAlertCheckTardio = !$this.showAlertCheckTardio;

                        $this.showLoader = false;
                    }
                }
            );
        };

        const handleGeolocationError = (error: GeolocationPositionError) => {
            this.showLoader = false;
            this.messageCheckTardio = this.getMessageGeolocationError(error);
            this.showAlertCheckTardio = !this.showAlertCheckTardio;
        };

        if (event.currentTarget.checked === true) {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(showPosition, handleGeolocationError, {
                    enableHighAccuracy: true
                });

            } else {
                // Manejo de la ausencia de geolocalización
                this.showLoader = false;
                this.messageCheckTardio = "La geolocalización no está disponible en este dispositivo o navegador.";
                this.showAlertCheckTardio = true;
            }
        }
    }

    generateNewIncidencia(id: any) {
        this.checkLists.map(function (dato) {
            if (dato.id == id && dato.incidenciaMarcada) {
                dato.incidenciaMarcada = true;
                dato.newIncidence = true;
            }

            return dato;
        });
    }

    filePhotoBillingsExtra(event: any, idAlbara: any): void {
        this.idAlbara = idAlbara;
        this.showListPhotosPodSPD = false;

        this.actionChecklist = 'POD';
        this.isCroppedImage = false;
        this.boolUploadLlegadaSalida = false;
        this.imageChangedEvent = event;
        this.buttonModal.nativeElement.click();
    }

    imageCropped(event: any): void {
        this.croppedImage = event.objectUrl;
    }

    cropImage() {
        this.isCroppedImage = true;
        this.boolUploadLlegadaSalida = false;
    }

    uploadFotoAlbaran() {
        this.showLoader = true;

        fetch(this.croppedImage)
            .then(response => response.blob())
            .then(blob => {
                const reader = new FileReader();
                reader.onload = async () => {
                    if (!reader.result) {
                        // TODO: Añadir mensaje de excepcion si no llega archivo (Un pop up por ejemplo)
                        return false;
                    }

                    if (typeof reader.result === "string") {
                        const base64 = reader.result.split(',')[1];

                        let image: Image = {
                            albara: this.idAlbara,
                            image: new File([], ""),
                            base64: base64,
                            tipoFoto: "finalitzacioAlbara",
                            idCheck: 0
                        };

                        this.dbService.addImage(image).then(async test => {
                            this.extraBillingsPhotosPOD.push(this.idAlbara);

                            let sonIguales = false;
                            if (this.extraBillingsPhotosPOD.length === this.albaranesMarcadosLlegadas.length) {
                                this.extraBillingsPhotosPOD.sort();
                                this.albaranesMarcadosLlegadas.sort((a, b) => a.idAlbara.localeCompare(b.idAlbara));

                                for (let i = 0; i < this.albaranesMarcadosLlegadas.length; i++) {
                                    sonIguales = this.albaranesMarcadosLlegadas[i].idAlbara === this.extraBillingsPhotosPOD[i];
                                }
                            }

                            if (sonIguales) {
                                await this.reload();

                                this.billingsPhotosPODDone = true;
                                this.showListPhotosPodSPD = true;
                                this.showLoader = false;
                            } else {
                                await this.reload();

                                this.showListPhotosPodSPD = true;
                                this.showLoader = false;
                            }
                        }).catch(err => {
                            this.showAlertErrorUploadPhotoAlbaran = !this.showAlertErrorUploadPhotoAlbaran;
                            this.messageErrorAlbaran = "Error al subir el POD.";

                            this.showLoader = false;
                        });

                        // Cambio de estado del Albarán a pre-finalizado.
                        //let response = await lastValueFrom(this.albaranService.changeStatusPreFinished(this.reference));
                    }

                    return true;
                };
                reader.readAsDataURL(blob);
            })
            .catch(error => {
                console.error("Error al convertir Object URL en Base64: " + error);
            });
    }

    previewUploadFotoLlegadasSalidas(event: Event, idAlbara: any, idCheck: any, recursiveFunc: boolean, actionChecklist: string) {
        let input = event.target as HTMLInputElement;

        if (!input.files || !input.files[0]) {
            return false;
        }

        this.file = input.files[0];
        const reader = new FileReader();

        reader.onload = (e: any) => {
            const imagePreviewUrl = e.target.result;
            this.base64image = imagePreviewUrl.split(',')[1];
        };

        reader.readAsDataURL(this.file);

        this.idAlbara = idAlbara;
        this.idCheck = idCheck;

        this.scannSuccess = false;
        this.boolUploadLlegadaSalida = true;
        this.actionChecklist = actionChecklist;

        if (recursiveFunc) {
            this.showListExtraPhotosSPC = false;
            this.buttonModal.nativeElement.click();
        } else {
            this.individualPhotoButtonModal.nativeElement.click();
        }

        return true;
    }

    previewUploadMandatoryPhotoSRCB(event: Event) {
        let input = event.target as HTMLInputElement;

        if (!input.files || !input.files[0]) {
            return false;
        }

        this.file = input.files[0];
        const reader = new FileReader();

        reader.onload = (e: any) => {
            const imagePreviewUrl = e.target.result;
            this.base64image = imagePreviewUrl.split(',')[1];
        };

        reader.readAsDataURL(this.file);

        this.showMandatoryPhoto = false;
        this.boolUploadLlegadaSalida = true;
        this.modalMandatoryPhotoSRCB.nativeElement.click();

        return true;
    }

    uploadFotoLlegadasSalidas(recursiveFunc: boolean) {
        this.showLoader = true;

        let image: Image = {
            albara: this.idAlbara,
            image: this.file,
            base64: "",
            tipoFoto: "albaranFoto",
            idCheck: this.idCheck
        };

        this.dbService.addImage(image).then(async test => {
            if (recursiveFunc) {
                this.extraBillingsPhotos.push(this.idAlbara);

                let sonIguales = false;
                if (this.extraBillingsPhotos.length === this.albaranesMarcadosLlegadas.length) {
                    this.extraBillingsPhotos.sort();
                    this.albaranesMarcadosLlegadas.sort((a, b) => a.idAlbara.localeCompare(b.idAlbara));

                    for (let i = 0; i < this.albaranesMarcadosLlegadas.length; i++) {
                        sonIguales = this.albaranesMarcadosLlegadas[i].idAlbara === this.extraBillingsPhotos[i];
                    }
                }

                if (sonIguales) {
                    this.billingsPhotosDone = true;
                    this.boolUploadLlegadaSalida = false;
                    this.showListExtraPhotosSPC = true;
                } else {
                    this.boolUploadLlegadaSalida = false;
                    this.showListExtraPhotosSPC = true;
                }

                this.showLoader = false;
            } else {
                await this.reload();
                this.showAlertUploadFotoLLegaSalida = !this.showAlertUploadFotoLLegaSalida;
                this.messageAlertUploadFotoLLegaSalida = "Imagen subida correctamente.";

                this.showLoader = false;
            }
        }).catch(err => {
            this.showListExtraPhotosSPC = false;
            this.showAlertUploadFotoLLegaSalida = !this.showAlertUploadFotoLLegaSalida;
            this.messageAlertUploadFotoLLegaSalida = "No se a podido subir la imagen correctamente.";

            this.showLoader = false;
        });
    }

    uploadPhotoSRCB() {
        this.showLoader = true;

        let check = this.checkLists.find(function (elemento) {
            return elemento.nom === "Salida recogida contenedor vacío";
        });

        let num = 0;
        if (check)
            num = parseInt(check.idCheck);

        let image: Image = {
            albara: this.idAlbara,
            image: this.file,
            base64: "",
            tipoFoto: "albaranFoto",
            idCheck: num
        };

        this.dbService.addImage(image).then(async test => {
            this.showLoader = false;
            this.showMandatoryPhoto = true;
            this.mandatoryPhotoDone = true;
        }).catch(err => {
            this.showListExtraPhotosSPC = false;
            this.showAlertUploadFotoLLegaSalida = !this.showAlertUploadFotoLLegaSalida;
            this.messageAlertUploadFotoLLegaSalida = "No se a podido subir la imagen correctamente.";

            this.showLoader = false;
        });
    }

    getId(idAlbara: any) {
        if (this.extraBillingsPhotos.includes(idAlbara)) {
            return "photoDelivered";
        } else {
            return "photoNotDelivered";
        }
    }

    getIdPOD(idAlbara: any) {
        if (this.extraBillingsPhotosPOD.includes(idAlbara)) {
            return "photoDelivered";
        } else {
            return "photoNotDelivered";
        }
    }

    marcarMasAlbaranesLlegada() {
        this.showLoader = true;

        this.albaranService.checkMasAlbaranesLlegadas(this.albaranesMarcadosLlegadas).subscribe(
            {
                next: next => {
                    this.showListLlegadasLLPC = false;
                    this.showLoader = false;
                }, error: error => {
                    this.showLoader = false;

                    this.showAlertUploadFotoLLegaSalida = !this.showAlertUploadFotoLLegaSalida;
                    this.messageAlertUploadFotoLLegaSalida = "Ha habido un error al marcar la salida.";
                }
            }
        )
    }

    marcarMasAlbaranesLLPD() {
        this.showLoader = true;
        this.showListLLPD = false;

        this.albaranService.checkMasAlbaranesLlegadas(this.albaranesMarcadosLlegadas).subscribe(
            {
                next: next => {
                    this.showLoader = false;
                }, error: error => {
                    this.showLoader = false;

                    this.showAlertUploadFotoLLegaSalida = !this.showAlertUploadFotoLLegaSalida;
                    this.messageAlertUploadFotoLLegaSalida = "Ha habido un error al marcar la salida.";
                }
            }
        )
    }

    marcarMasAlbaranesLLPP() {
        this.showLoader = true;
        this.showListLLPP = false;

        this.albaranService.checkMasAlbaranesLlegadas(this.albaranesMarcadosLlegadas).subscribe(
            {
                next: next => {
                    this.showLoader = false;
                }, error: error => {
                    this.showLoader = false;

                    this.showAlertUploadFotoLLegaSalida = !this.showAlertUploadFotoLLegaSalida;
                    this.messageAlertUploadFotoLLegaSalida = "Ha habido un error al marcar la salida.";
                }
            }
        )
    }

    marcarMasAlbaranesSPC() {
        this.showListExtraPhotosSPC = true;
        this.showListMoreAlbaransSPC = false;
        this.isMultitasking = this.showListNextPointsSPCaux;
    }

    sendMoreAlbaransSPC() {
        this.showLoader = true;
        this.showListNextPointsSPC = false;

        this.albaranService.checkMasAlbaranesSPC(this.idTransport,this.idAlbara,this.albaranesMarcadosLlegadas, this.llocsMarcadosSPC).subscribe(
            {
                next: async (next:any) => {
                    await this.reload();
                    this.showLoader = false;

                    if (next.status != 'success'){
                        this.showAlertUploadFotoLLegaSalida = !this.showAlertUploadFotoLLegaSalida;
                        this.messageAlertUploadFotoLLegaSalida = "Salida marcada correctamente.";
                    }

                },
                error: async error => {
                    await this.reload();
                    this.showLoader = false;

                    this.showAlertPlaceToGo = !this.showAlertPlaceToGo;
                    this.messageAlertPlaceToGo = error.error.message;
                }
            }
        );
    }

    sendMoreAlbaransSPD() {
        this.showLoader = true;

        this.albaranService.checkMasAlbaranesSPD(this.albaranesMarcadosLlegadas).subscribe(
            {
                next: next => {
                    this.showListPhotosPodSPD = true;
                    this.showListMoreAlbaransSPD = false;

                    this.showLoader = false;
                },
                error: error => {
                    this.showListPhotosPodSPD = true;
                    this.showListMoreAlbaransSPD = false;

                    this.showLoader = false;

                    this.showAlertUploadFotoLLegaSalida = !this.showAlertUploadFotoLLegaSalida;
                    this.messageAlertUploadFotoLLegaSalida = "Ha habido un error al marcar la salida.";
                }
            }
        );
    }

    marcarMasAlbaranesSPP() {
        this.showListMoreAlbarans = false;
    }

    sendMoreAlbaransSPP() {
        this.showLoader = true;

        this.albaranService.checkMasAlbaranesSPC(this.idTransport,this.idAlbara, this.albaranesMarcadosLlegadas, this.llocsMarcadosSPC).subscribe(
            {
                next: next => {
                    this.showListSPP = false;
                    this.showListNextPointsSPP = false;

                    this.showLoader = false;
                },
                error: error => {
                    this.showListSPP = false;
                    this.showListNextPointsSPP = false;

                    this.showLoader = false;

                    this.showAlertUploadFotoLLegaSalida = !this.showAlertUploadFotoLLegaSalida;
                    this.messageAlertUploadFotoLLegaSalida = "Ha habido un error al marcar la salida.";
                }
            }
        );
    }

    saveDeliveryNoteWaypoints(albaran: any, event: Event) {
        // TODO: Quitar el ts-ignore este.
        /*if(!event.currentTarget) {
            return false;
        }*/

        // @ts-ignore
        if (event.currentTarget.checked === true) {
            let newAlbaranMarcadoLLegada = {
                idAlbara: albaran.idAlbara,
                reference: albaran.id,
                idCheck: this.idCheck,
                latitude: this.latitude,
                longitude: this.longitude,
                lloc: this.idLloc,
                bultos: albaran.bultos,
                kg: albaran.pes,
                volumen: albaran.volum,
            };

            this.albaranesMarcadosLlegadas.push(newAlbaranMarcadoLLegada);
        } else {
            const index = this.albaranesMarcadosLlegadas.indexOf(albaran.id);

            if (index !== -1) {
                this.albaranesMarcadosLlegadas.splice(index, 1);
            }
        }

        //return true;
    }

    saveDestinationPlaceId(idLloc: any) {
        if (this.selectedCheckboxId === idLloc) {
            this.selectedCheckboxId = null;
            this.llocsMarcadosSPC = null;
        } else {
            this.selectedCheckboxId = idLloc;
            this.llocsMarcadosSPC = idLloc;
        }
    }

    async closeAlertSuccessAlbaran() {
        if (this.modeTransport === 'MARITIM' && this.tipusTransport === 'Importació' && this.idRecollidaEntregaContenidor !== null) {
            this.showAlertSuccessUploadPhotoAlbaran = !this.showAlertSuccessUploadPhotoAlbaran;
        } else {
            this.showAlertSuccessUploadPhotoAlbaran = !this.showAlertSuccessUploadPhotoAlbaran;
            await this.router.navigate(['/albaranes']);
        }
    }

    closeAlertErrorAlbaran() {
        this.showAlertErrorUploadPhotoAlbaran = !this.showAlertErrorUploadPhotoAlbaran;
    }

    closeAlertSuccessScannBarcode() {
        this.showAlertSuccessScannBarcode = false;
    }

    async closeAlertCheckTardio() {
        await this.reload();
        this.showAlertCheckTardio = !this.showAlertCheckTardio;
    }

    closeAlertUploadFotoLLegaSalida() {
        this.showAlertUploadFotoLLegaSalida = !this.showAlertUploadFotoLLegaSalida;
    }

    closeAlertPlaceToGo(): void {
        this.showAlertPlaceToGo = !this.showAlertPlaceToGo;
        this.showListNextPointsSPC = true;
    }

    closeAlertCreateChecks() {
        this.showAlertCreateChecks = !this.showAlertCreateChecks;
    }

    async closeAlertAlbaranCompletedSECP() {
        this.showAlertAlbaranCompletedSECP = !this.showAlertAlbaranCompletedSECP;
        await this.router.navigate(['/albaranes']);
    }

    closeMarcarAlbaranesLlegadas() {
        this.showListLlegadasLLPC = !this.showListLlegadasLLPC;
    }

    closeMarcarAlbaranesLLPD() {
        this.showListLLPD = !this.showListLLPD;
    }

    closeMarcarAlbaranesLLPP() {
        this.showListLLPP = !this.showListLLPP;
    }

    closeMarcarAlbaranesSPC() {
        this.showListExtraPhotosSPC = true;
        this.showListMoreAlbaransSPC = false;
        this.albaranesMarcadosLlegadas = [this.albaranesMarcadosLlegadas[0]];
        this.isMultitasking = this.showListNextPointsSPCaux;
    }

    closeModalPhotosBillingsExtra() {
        this.showLoader = true;

        if (this.isMultitasking) {
            this.showListNextPointsSPC = true;
            this.showListExtraPhotosSPC = false;
            this.showLoader = false;
        } else {
            this.showListExtraPhotosSPC = false;

            this.albaranService.checkMasAlbaranesSPC(this.idTransport, this.idAlbara, this.albaranesMarcadosLlegadas, this.llocsMarcadosSPC).subscribe(
                {
                    next: async (next:any) => {
                        await this.reload();
                        this.showLoader = false;
                        this.actionChecklist = '';
                        if (next.status != 'success') {
                            this.showAlertUploadFotoLLegaSalida = !this.showAlertUploadFotoLLegaSalida;
                            this.messageAlertUploadFotoLLegaSalida = "Salida marcada correctamente.";
                        }

                    },
                    error: async error => {
                        await this.reload();
                        this.showLoader = false;

                        this.actionChecklist = '';

                        this.showAlertUploadFotoLLegaSalida = !this.showAlertUploadFotoLLegaSalida;
                        this.messageAlertUploadFotoLLegaSalida = "Ha habido un error al marcar la salida.";
                    }
                }
            );
        }
    }

    async closeModalMandatoryPhotoSRCB() {
        await this.reload();
        this.showMandatoryPhoto = false;
    }

    closeMarcarAlbaranesSPD() {
        this.showListPhotosPodSPD = true;
        this.showListMoreAlbaransSPD = false;
        this.albaranesMarcadosLlegadas = [this.albaranesMarcadosLlegadas[0]];
    }

    async closeModalPhotosBillingsExtraSPD() {
        await this.reload();

        this.actionChecklist = '';

        this.showListPhotosPodSPD = false;
        this.showAlertSuccessUploadPhotoAlbaran = !this.showAlertSuccessUploadPhotoAlbaran;

        if (this.modeTransport === 'MARITIM' && this.tipusTransport === 'Importació' && this.idRecollidaEntregaContenidor !== null) {
            this.messageSuccessAlbaran = "Fotografia albarán subida correctamente.";
        } else {
            this.messageSuccessAlbaran = "ALBARÁN COMPLETADO Finalización definitiva en proceso";
        }
    }

    closeMarcarAlbaranesSPP() {
        this.showListSPP = !this.showListSPP;
    }

    closeModal() {
        this.boolUploadLlegadaSalida = false;
        this.isCroppedImage = false;
        this.croppedImage = [];

        if (this.actionChecklist === 'SPC') {
            this.showListExtraPhotosSPC = true;
            this.showListMoreAlbaransSPC = false;
            this.isMultitasking = this.showListNextPointsSPCaux;
        }

        if (this.actionChecklist === 'POD') {
            this.showListPhotosPodSPD = true;
            this.showListMoreAlbaransSPD = false;
        }
    }

    closeModalSRCB() {
        this.showMandatoryPhoto = false;
    }

    openModalCheckAWB(packages: string, checkId: string) {
        if (this.isCheckedAWB === 1) {
            this.showAlertSuccessScannBarcode = true;
            this.messageSuccessScannBarcode = "Ya has terminado el proceso de escaneo."
        } else if (this.isCheckedAWB === 0) {
            this.showCheckAWB = true;
            this.bultos = packages;
            this.idCheck = checkId;
        }
    }

    openModalCheckBultosDestinyAWB(idCheck: any, descriptionName: string) {
        this.originNamesLinkedAlbarans = descriptionName;
        this.showCheckBultosAWB = true;

        /*
        this.showLoader = true;
        this.albaranService.getLinkedAlbarans(idCheck, this.reference).subscribe(
            {
                next: (next: any) => {
                    this.llocsLinkedAlbaransAWB = next;

                    this.originNamesLinkedAlbarans = "";
                    this.llocsLinkedAlbaransAWB.forEach(item => {
                        this.originNamesLinkedAlbarans += item.lloc + "; ";
                    });

                    this.showCheckBultosAWB = true;
                    this.showLoader = false;
                }
            }
        )
         */
    }

    partialCheck() {
        this.showCheckAWBScanner = true;
        this.showCheckAWB = false;
        this.isPartial = 1;
        this.isTotal = 0;
    }

    totalCheck() {
        this.showCheckAWBScanner = true;
        this.showCheckAWB = false;
        this.isTotal = 1;
        this.isPartial = 0;
    }

    async closeModalCheckAWBDone() {
        this.showCheckAWBDone = false;
        this.isNotAppropiate = false;
        this.checkedAppropiate = false;
        this.waitCheck = false;
        this.showCheckAWBManually = false;
        this.showCheckAWBScanner = false;
        this.showCheckAWB = false;

        await this.reload()
    }

    isAppropriate() {
        this.showCheckBultosAWB = false;
        this.showCheckDestinyAWB = true;
    }

    goBackToBultos() {
        this.showCheckBultosAWB = true;
        this.showCheckDestinyAWB = false;
    }

    notIsAppropriate() {
        this.isNotAppropiate = true;
        this.checkedAppropiate = true;
    }

    goBackBultos() {
        this.isNotAppropiate = false;
        this.checkedAppropiate = false;
    }

    async isAppropriateDestiny() {
        this.showLoader = true;
        this.showCheckDestinyIATA = false;
        this.showCheckDestinyAWB = false;

        try {
            await lastValueFrom(this.albaranService.checkBultosDestinyAWB(true, this.idAlbara, this.idTransport));

            this.isNotAppropiate = false;
            this.checkedAppropiate = false;
            this.checkedAppropiateDestiny = false;
            this.isNotAppropiateDestiny = false;
            this.checkedAppropiateDestinyIATA = false;
            this.showLoader = false;
            await this.reload();
        } catch (error) {
            this.showLoader = false;
        }
    }

    notIsAppropriateDestiny() {
        this.isNotAppropiateDestiny = true;
        this.checkedAppropiateDestiny = true;
    }

    goBackDestiny() {
        this.isNotAppropiateDestiny = false;
        this.checkedAppropiateDestiny = false;
    }

    notIsAppropriateDestinyIATA() {
        this.showCheckDestinyAWB = true;
        this.showCheckDestinyIATA = false;
        this.isNotAppropiateDestiny = true;
        this.checkedAppropiateDestiny = true;
        this.checkedAppropiateDestinyIATA = false;
    }

    checkDestinyIATA() {
        this.showCheckDestinyIATA = true;
        this.showCheckDestinyAWB = false;
        this.codeIATAtoCheck = "";
    }

    goBackCheckIATA() {
        this.showCheckDestinyIATA = false;
        this.showCheckDestinyAWB = true;
        this.codeIATAtoCheck = "";
    }

    async funcCheckDestinyIATA() {
        this.showLoader = true;
        this.showCheckDestinyIATA = false;

        try {
            let infoCodeIATA: any = await lastValueFrom(this.albaranService.getInfoByCodeIATA(this.codeIATAtoCheck));
            this.nomAeroport = infoCodeIATA.nom;
            this.municipiAeroport = infoCodeIATA.municipi;
            this.paisAeroport = infoCodeIATA.pais;

            this.checkedAppropiateDestinyIATA = true;
            this.showLoader = false;
            this.showCheckDestinyIATA = true;
        } catch (error) {
            this.showLoader = false;

        }
    }

    closeModalCheckAWB() {
        this.showCheckAWB = false;
    }

    closeModalCheckBultosAWB() {
        this.showCheckBultosAWB = false;
        this.isNotAppropiate = false;
        this.checkedAppropiate = false;
    }

    closeModalCheckDestinyAWB() {
        this.showCheckDestinyAWB = false;
        this.isNotAppropiate = false;
        this.checkedAppropiate = false;
        this.checkedAppropiateDestiny = false;
        this.isNotAppropiateDestiny = false;
    }

    closeModalCheckDestinyIATA() {
        this.showCheckDestinyIATA = false;
        this.isNotAppropiate = false;
        this.checkedAppropiate = false;
        this.checkedAppropiateDestiny = false;
        this.isNotAppropiateDestiny = false;
        this.checkedAppropiateDestinyIATA = false;
    }

    closeModalCheckAWBScanner() {
        this.showCheckAWBScanner = false;
        this.showCheckAWB = false;
        this.isNotAppropiate = false;
        this.checkedAppropiate = false;
    }

    scannNextBarcode() {
        this.showCheckAWBDone = false;
        this.showCheckAWBScanner = true;
        this.isNotAppropiate = true;
        this.checkedAppropiate = true;
        this.waitCheck = false;
    }

    ngDoCheck() {
        this.deviceCurrent = this.qrDriver.deviceCurrent;
        this.deviceSelected = this.qrDriver.deviceSelected;
        this.availableDevices = this.qrDriver.availableDevices;
        this.enabled = this.qrDriver.enabled;
    }

    async browserError(error: any) {
        await this.qrDriver.browserError(error);
    }

    async ionViewWillEnter() {
        this.enabled = true;
        await this.start();
    }

    async start() {
        this.qrDriver.start().then(async (code) => {
            await this.saveResult(code);
        }).finally(async () => {
            await this.start();
        });
    }

    async ionViewWillLeave() {
        this.enabled = false;
        await this.close();
    }

    async close() {
        await this.qrDriver.close();
    }

    async checkBrowser(code: string) {
        if (code && this.qrDriver.check(code)) {
            if (!this.procesing) {
                await this.saveResult(code).catch(() => {
                });
            }
        } else if (code && !this.qrDriver.check(code)) {
            const toast = await this.toast.create({
                message: 'Error de lectura',
                color: 'danger',
                position: 'bottom',
                translucent: true,
                duration: 2000
            });
            await toast.present();
        }
    }


    async endBarcodeTask() {
        this.showLoader = true;
        try {
            const response: any = await lastValueFrom(this.albaranService.endBarcodeTask(this.idCheck, this.deliveryNoteId, this.idTransport));
            this.showLoader = false;
            await this.closeModalCheckAWBDone();
            if (response.status === "success") {
                this.idMessageCheckAWBDone = "checkSuccess";
            } else {
                this.idMessageCheckAWBDone = "checkFailed";
            }
            this.showAlertSuccessScannBarcode = true;
            this.messageSuccessScannBarcode = response.message;

        } catch (e: any | { message?: string, exception?: string }) {
            // TODO: Error message
            this.showLoader = false;
            this.showAlertSuccessScannBarcode = true;
            this.messageSuccessScannBarcode = e.error.message;

        }
    }

    async saveResult(code: any) {
        this.showLoader = true;

        if (!this.waitCheck) {
            try {
                const response: any = await lastValueFrom(this.albaranService.checkBarcodeAWB(code, this.idAlbara, this.isPartial, this.isTotal, 1, 0, this.idTransport));
                this.waitCheck = true;
                this.showCheckAWBScanner = false;
                this.showLoader = false;
                await this.showModalCheckAWBDoneByResponse(response);
            } catch (e: any | { message?: string, exception?: string }) {
                // TODO: Error message
                this.showLoader = false;
                this.showAlertSuccessScannBarcode = true;
                this.messageSuccessScannBarcode = e.error.message;

            }

        }

        return new Promise(async () => {
            /*await this.router.navigate(['/albaranes', code]);*/
        });
    }

    onCamerasFound(devices: MediaDeviceInfo[]): void {
        this.qrDriver.onCamerasFound(devices);
    }

    onDeviceChange(device: MediaDeviceInfo) {
        this.qrDriver.onDeviceChange(device);
    }

    onHasPermission(has: boolean) {
        this.qrDriver.onHasPermission(has);
    }

    onTorchCompatible(isCompatible: boolean): void {
        this.qrDriver.onTorchCompatible(isCompatible);
    }

    scannBarcodeManually() {
        this.showCheckAWBManually = true;
        this.showCheckAWBScanner = false;
    }

    async showModalCheckAWBDoneByResponse(response: any) {
        this.isTotalSuccess = response.isTotalSuccess;
        this.isPartialSuccess = response.isPartialSuccess;
        if (response.status === "success") {
            this.idMessageCheckAWBDone = "checkSuccess";
        } else {
            this.idMessageCheckAWBDone = "checkFailed";
        }

        if (response.successSave){
            await this.closeModalCheckAWBDone();
            this.showAlertSuccessScannBarcode = true;
            this.messageSuccessScannBarcode = response.message;
        }else{
            this.showCheckAWBDone = true;
            this.messageCheckAWBDone = response.message;
        }

    }

    async sendBarcodeManually() {
        let code = "";
        this.showLoader = true;
        this.showCheckAWBManually = false;
        this.showLoader = false;

        if (this.inputCheckBarcodeManually !== undefined) {
            code = this.inputCheckBarcodeManually.nativeElement.value;
        }

        try {
            const response: any = await lastValueFrom(this.albaranService.checkBarcodeAWB(code, this.idAlbara, this.isPartial, this.isTotal, 0, 1, this.idTransport));
            this.showCheckAWBManually = false;
            this.showCheckAWBScanner = false;

            await this.showModalCheckAWBDoneByResponse(response);

        } catch (e: any | { message?: string; exception?: string }) {
            // TODO: Error message
            this.showLoader = false;
            this.showAlertSuccessScannBarcode = true;
            this.messageSuccessScannBarcode = e.error.message;
        }
    }

    closeModalCheckAWBManually() {
        this.showCheckAWBManually = false;
        this.showCheckAWBScanner = true;
    }

    protected readonly TransportMode = TransportMode;
    protected readonly TransportStatus = TransportStatus;

    hasDisableTaskList(statusRoute: string):boolean {
        return [this.TransportStatus.completed, this.TransportStatus.cancelled, this.TransportStatus.postponed].includes(statusRoute);

    }

    isDateExpired(date:string):boolean {
        // Dividir la fecha
        const [year, month, day] = date.split('-').map(Number);

        if (isNaN(year) || isNaN(month) || isNaN(day)) {
            return true;//throw new Error("La fecha contiene valores no válidos.");
        }

        if (!year || !month || !day) {
            return true;// throw new Error('Fecha inválida o formato incorrecto');
        }

        // Crear la fecha asegurándote del formato
        const inputDate = new Date(year, month - 1, day);

        // Validar que sea una fecha válida
        if (isNaN(inputDate.getTime())) {
            return true;//throw new Error('La fecha no es válida');
        }

        const today = new Date();
        today.setHours(0, 0, 0, 0);

        return inputDate < today;
    }

}
