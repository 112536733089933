export const TransportStatus = {
    preAssigned: 'PA',
    assigned: 'A',
    partiallyAssigned: 'AP',
    initiated: 'INI',
    completed: 'FIN',
    postponed: 'APL',
    cancelled: 'ANU',
    pending: 'PEN',
    preCompleted : 'PREFIN'
};
