import { Component } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { AlbaranService } from "../../../services/albaran.service";
import { InfoAlbaran } from "../../../models/infoAlbaran";
import { global } from "../../../services/global";
import { AuthService} from "../../../services/auth.service";
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';


@Component({
    selector: 'app-albaran-content',
    templateUrl: './albaran-content.component.html',
    styleUrls: ['./albaran-content.component.scss'],
    standalone: false
})
export class AlbaranContentComponent {

    showAlert: boolean;
    messageAlert :string;

    name: string = "";
    url = global.getUrl();

    infoAlbara: InfoAlbaran = new InfoAlbaran();

    numeroRuta: number = 0;
    idTransport: any;
    idAlbara: any;
    token : string;
    showLoader: boolean = false;

    constructor(
        private route: ActivatedRoute,
        private albaranService: AlbaranService,
        private auth: AuthService,
        private http: HttpClient,
    ) {
        this.route.params.subscribe(
            (params) => {
                this.idAlbara = params['idAlbara'];
                this.idTransport = params['idTransport'] || null; // Manejo del parámetro opcional

            }
        );
        this.token = this.auth.getToken();
        this.showAlert = false;
        this.messageAlert = '';
    }

    ngOnInit(): void {
        this.showLoader = true;

        this.albaranService.getInfoAlbaran(this.idAlbara).subscribe(
            {
                next: infoAlbaran => {
                    this.infoAlbara = infoAlbaran;
                    this.name = "Albarán - " + this.infoAlbara.reference;
                    this.infoAlbara.rutesADR = infoAlbaran.rutasADR;
                    this.numeroRuta = 1;

                    this.albaranService.modeTransport = infoAlbaran.modeTransport;
                    this.albaranService.tipusTransport = infoAlbaran.tipusTransport;
                    this.albaranService.idRecollidaEntregaContenidor = infoAlbaran.idRecollidaEntregaContenidor;

                    this.showLoader = false;
                }
            }
        )
    }

    downloadDeliveryNote(deliveryNoteId: string, token: string): void {
        // Construir la URL
        this.showLoader = true;
        const url = this.url + `deliveryNote/dmByDriver?id=${deliveryNoteId}&token=${token}`;
        // Hacer la petición GET al backend
        this.http.get(url, { responseType: 'blob' }).subscribe(
            (response: Blob) => {
                this.showLoader = false;
                // Si la respuesta es un archivo PDF (tipo blob), proceder con la descarga
                const fileURL = URL.createObjectURL(response);
                const a = document.createElement('a');
                a.href = fileURL;
                a.download = `Albaran_TRM_${deliveryNoteId}.pdf`; // O el nombre que desees
                a.click();
                URL.revokeObjectURL(fileURL); // Limpiar la URL objeto
            },
            (error: HttpErrorResponse) => {
                    this.showLoader = false;
                    this.showAlert = true;
                    this.messageAlert ='No se ha podido procesar tu solicitud'
            //this.showToast('No se ja podido procesar tu solicitud');

            }
        );
    }


    closeAlert(){
        this.showAlert = false;
    }
}
